/* Button.css */

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: none;
	background-color: #e1f5fe; /* light blue */
	text-align: center;
	transition: transform 0.2s ease;
	height: 100%;
	width: 100%;
	border-radius: 10px; /* rounded edges */
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
	gap: 5%;
	padding: 10px;
}

@media screen and (min-width: 640px) {
	.button {
		padding: 20px;
	}
}

.button:last-child {
	margin-right: 0; /* No margin for the last button */
}

.button:hover {
	transform: scale(1.05);
}

.image-container.large img {
	max-width: 100%;
	width: 300px;
}

.button img {
	max-width: 30%; /* smaller image size */
}

.button-text {
	font-size: medium;
	margin: 0;
	color: #333;
}

@media only screen and (min-width: 768px) {
	.button-text {
		font-size: x-large;
		font-weight: 700;
	}

	.button img {
		max-width: 50%;
	}
}
