.home {
	width: 100%;
	height: 100vh;
	align-items: center;
	display: flex;
	flex-direction: column;
	background-color: #aaf1df;
	background-image: url("../assets/background.jpg"); /* Specify the path to your image */
	background-size: cover; /* Cover the entire container */
	background-position: top;
}

.main-content {
	display: flex;
	width: 100%;
	overflow: auto;
}

.buttons-container {
	display: grid;
	justify-content: center;
	align-items: center;
	overflow: auto;
	grid-template-columns: 1fr;
	padding: 30px;
	gap: 3% 2.5%;
	width: 100%;
	height: 100%;
}

@media screen and (min-width: 640px) {
	.buttons-container {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (min-width: 768px) {
	.buttons-container {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media screen and (min-width: 1024px) {
	.buttons-container {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}