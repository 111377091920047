.sidebar {
	position: fixed;
	right: 0;
	bottom: 0;
	width: 10vw;
	display: flex;
	align-items: center;
	height: 100%;
	flex-direction: column;
	padding: 0 10px;
}

.sidebar-container {
	color: transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 10vw;
	padding: 0 10px;
}

.imageClassName-first {
	margin-bottom: 200px; /* Adjust the margin-top value according to your requirement */
}

.sideimage {
	width: 100%;
}

.message {
	width: 100%;
}

.colorWheel {
	position: relative;
	cursor: pointer;
}

.colorWheelDropdown {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-wrap: wrap;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	padding: 5px;
	z-index: 1000;
}

.colorOption {
	width: 20px;
	height: 20px;
	margin: 5px;
	border-radius: 50%;
}

.colorOption:hover {
	border: 2px solid #000;
}
