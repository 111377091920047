@import "theme.css";
@import "general.css";
@import "components/index.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1 {
		@apply text-5xl;
	}
	h2 {
		@apply text-3xl;
	}
	h3 {
		@apply text-lg;
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
