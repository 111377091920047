.navbar {
	z-index: 100;
	width: 100%;
	height: 100px;
	background: #21325e;
	background: #2aad85;
	position: relative;
	display: flex;
	justify-self: center;
	align-items: center;
}

.image {
	width: 75px;
	height: 75px;
	margin: 10px;
	margin-right: 24px;
}

.white {
	color: white;
}

.head-text {
	color: white;
	font-size: 3rem; /* Equivalent to text-3xl */
	font-weight: 600; /* Equivalent to font-semibold */
	line-height: 2; /* Equivalent to sm:leading-snug */
	margin-left: 32px;
}

.dropdown-content {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius: 4px;
	overflow: hidden;
}

.dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content a:hover {
	background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
	display: block;
	animation: slideDown 0.3s ease-in-out;
}

@keyframes slideDown {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
